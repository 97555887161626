import React from "react"
import { Link, Redirect } from "react-router-dom"
import auth from '../../utils/auth'
import Select from "../../components/Select"
import cfg from '../../utils/config'
import helper from "../../utils/helper"
import axios from 'axios'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ButtonsForm from "../../components/ButtonsForm"

class FormNovo extends React.Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { descricao: "", cliente: { label: 'Nenhum', value: null } },
        optionsCliente: [],
        validacao_formulario: { descricao: true },
        redirect: false,
        cooldown: false
    }

    handleChangeRelatorio = (e, field) => {
        const { detalhesDefault } = this.state
        detalhesDefault[field] = e
        this.setState({ detalhesDefault })
    }

    componentDidMount() {
        if(this.userInfo.tipo !== "cliente") {
            this.getClients()
        }
    }

    saveAction = () => {
        this.setState({ cooldown: true })

        let { detalhesDefault } = this.state
        let not_validate = false
        let validate_form = this.state.validacao_formulario

        for (let key in validate_form) {
            if (!detalhesDefault[key]) {
                validate_form[key] = false
                not_validate = true
            } else
                validate_form[key] = true
        }

        this.setState({ validacao_formulario: validate_form })

        if(not_validate) {
            this.setState({cooldown: false})
            return
        }

        let verb = 'POST'
        let url = cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/relatorio/`

        const bodyFormData = new FormData()
        bodyFormData.append("descricao", detalhesDefault.descricao)

        if (this.userInfo?.tipo == "cliente" && this.userInfo?.cliente) {
            bodyFormData.append('cliente_id', this.userInfo?.cliente)
        } else {
            if (detalhesDefault.cliente?.value) {
                bodyFormData.append('cliente_id', detalhesDefault.cliente?.value)
            } else {
                bodyFormData.delete('cliente_id')
            }
        }

        axios({ method: verb, url: url, data: bodyFormData, ...this.configMe }).then(res => {
            helper.dispatchEvent("showAviso", {
                message: 'Relatorio salvo com sucesso',
                callback: () => this.setState({ redirect: true, path: "/relatorio-customizado" }),
                callbackClose: verb === 'POST' ? () => {
                    window.location.href = `/relatorio-customizado/${res.data.id}/editar`
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } : null
            })
        })
        .catch((error) => {
            helper.dispatchEvent("showAviso", {
                message: error.response.data.cliente[0],
            })
        })

        setTimeout(() => this.setState({ cooldown: false }), 2000)
    }

    getClients = async () => {
        await axios.get(cfg.base_api_url + cfg.api_v2 + '/' + this.userInfo.empresa + '/cliente/' + `?monitorado_restrito=${this.userInfo.monitorado_restrito}`, this.configMe)
            .then(cliente => {
                let optionsCliente = []
                optionsCliente.push({ label: 'Nenhum', value: null })

                if (cliente.data?.results.length > 0) {
                    cliente.data?.results?.forEach((c) => {
                        optionsCliente.push({ "value": c.id, "label": c.nome_fantasia })
                    })
                }

                this.setState({ optionsCliente })
            })
    }

    render() {
        const { detalhesDefault, cooldown } = this.state

        if (this.state.redirect)
            return <Redirect to="/relatorio-customizado" />

        return (
            <>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Relatório Customizado</h4>
                        <div className="gray-background">
                            <i className="fa fa-file-alt fa-2x"></i>
                        </div>
                    </span>
                </div>

                <Tabs>
                    <TabList>
                        <Tab>Configuração</Tab>
                        <Tab disabled={true}>Gráficos</Tab>
                    </TabList>
                    <TabPanel>
                        <form className="hdv-form-default">
                            <div className="row">
                                <div className={this.userInfo?.tipo != "cliente" ? "col-md-8 pr-4" : "col-md-12"}>
                                    <label style={{marginTop: "0px"}} className="required ">Descrição</label>
                                    <input type="text" onChange={(e) => this.handleChangeRelatorio(e.target.value, 'descricao')} value={detalhesDefault.descricao} />
                                    {!this.state.validacao_formulario.descricao ? <span id='validacao-descricao'>Campo Obrigatório</span> : ""}
                                </div>

                                {this.userInfo?.tipo != "cliente"
                                    ?
                                    <div className="col-md-4 pl-0">
                                        <label className="required mt-0">Cliente</label>
                                        <Select
                                            value={detalhesDefault.cliente}
                                            onChange={(e) => this.handleChangeRelatorio(e, 'cliente')}
                                            options={this.state.optionsCliente}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </form>
                    </TabPanel>
                </Tabs>

                <div className="hdv-btn-group">
                    <ButtonsForm
                        route="/relatorio"
                        edit={false}
                        cooldown={this.state.cooldown}
                        buttons={[
                            {
                                method: "post",
                                button: <button onClick={this.saveAction} className="hdv-btn-forms hdv-btn-green" disabled={cooldown}>Salvar</button>
                            }
                        ]}
                    />
                    <Link to="/relatorio-customizado">
                        <button disabled={cooldown} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </Link>
                </div>
            </>
        )
    }
}

export default FormNovo