import React, { Component } from "react";
import axios from 'axios';
import auth from "../../utils/auth";
import cfg from "../../utils/config";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "../../components/Select";
import helper from "../../utils/helper";

class EnvioConvite extends Component {
    
    state = {
        tipoEnvio: { label: "Receber convite por e-mail", value: "email" },
        detalhesSenha: {senha: null, confirmar: null},
        validacaoSenha: {senha: true, confirmar: true},
        tipoInput: { senha: "password", confirmar: "password" },
        cooldown: false
    }

    handleChangeTipoEnvio = (selectedOption) => {
        let tipoEnvio = this.state.tipoEnvio
        tipoEnvio = selectedOption
        this.setState({tipoEnvio})
    }

    handleChangeSenha = (value, field) => {
        const detalhesSenha = Object.assign({}, this.state.detalhesSenha)
        detalhesSenha[field] = value
        this.setState({detalhesSenha})
    }

    handlechangeTipoInput = (field) => {
        const tipoInput = Object.assign({}, this.state.tipoInput)
        tipoInput[field] = tipoInput[field] === "password" ? "text" : "password"
        this.setState({ tipoInput })
    }

    enviarConvite = () => {
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()

        this.setState({cooldown: true})

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/reenvio-convite/' + this.props.clienteSelecionado + '/', config_me)
        .then((response) => {
            helper.dispatchEvent("showAviso", { message: `${response.data.msg}`, callbackClose: () => this.toggleModal(false) })
        })
        .catch((error) => {
            helper.dispatchEvent("showAviso", { message: `${error.response.data.msg}`, callbackClose: () => this.toggleModal(false) })
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    criarSenha = () => {
        const detalhesSenha = this.state.detalhesSenha
        const validacao = Object.assign({}, this.state.validacaoSenha)

        let error = false

        for(let i in detalhesSenha) {
            if(!detalhesSenha[i]) {
                validacao[i] = false
                error = true
            } else {
                validacao[i] = true
            }
        }

        this.setState({ validacaoSenha: validacao })

        if(error) {
            return
        }


        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()

        this.setState({cooldown: true})

        axios.get(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/validacao-usuario-cliente/' + this.props.clienteSelecionado + '/', config_me)
        .then((response) => {
            const data = response.data
    
            if(data.token) {
                const requestURL = cfg.base_api_url + cfg.api_v2 + '/create-customer-access/' 

                const bodyFormData = {
                    new_password: detalhesSenha.senha,
                    confirm_password: detalhesSenha.confirmar,
                    token: data.token
                }

                axios({
                    method: "POST",
                    url: requestURL,
                    data: JSON.stringify(bodyFormData),
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
                })
                .then((response) => {
                    helper.dispatchEvent("showAviso", {
                        message: response.data.detail,
                        callbackClose: () => this.toggleModal(false)
                    })
                })
                .catch((error) => {
                    helper.dispatchEvent("showAviso", {
                        message: error.response.data.detail,
                    })
                })

            } else {
                const requestURL = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + data.cliente_id + '/redefinir-senha-usuario/' + data.usuario_id + '/'
                const bodyFormData = {
                    new_password: detalhesSenha.senha,
                    confirm_password: detalhesSenha.confirmar
                }

                helper.dispatchEvent("showAviso", {
                    message: data.msg,
                    callback: () => {
                        axios({
                            method: "POST",
                            url: requestURL,
                            data: JSON.stringify(bodyFormData),
                            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + auth.getToken() }
                        })
                        .then((response) => {
                            helper.dispatchEvent("showAviso", {
                                message: response.data.msg,
                                callbackClose: () => this.toggleModal(false)
                            })
                        })
                        .catch((error) => {
                            helper.dispatchEvent("showAviso", {
                                message: error.response.data.msg,
                            })
                        })
                    }
                })
            }
        })

        setTimeout(() => this.setState({ cooldown: false }), 1000)
    }

    toggleModal = (status) => {
        if(!status) {
            this.setState({
                modalEnvioConvite: false,
                tipoEnvio: { label: "Receber convite por e-mail", value: "email" },
                detalhesSenha: {senha: null, confirmar: null},
                validacaoSenha: {senha: true, confirmar: true},
                tipoInput: { senha: "password", confirmar: "password" }
            })
        }

        this.props.toggleModal(status)
    }



    render() {
        const { tipoEnvio, tipoInput, validacaoSenha, detalhesSenha, cooldown } = this.state
        const statusModal = this.props.statusModal

        return (
            <Modal className="modal-large" isOpen={statusModal} toggle={() =>  this.toggleModal(false)}>
                <ModalHeader toggle={() =>  this.toggleModal(false)}>
                    Enviar Convite / Criar Senha
                </ModalHeader>
                <ModalBody>
                    <div className='hdv-form-default'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='required'>Opções</label>
                                <Select
                                    onChange={(e) => this.handleChangeTipoEnvio(e)}
                                    value={tipoEnvio}
                                    options={[
                                        { label: "Receber convite por e-mail", value: "email" },
                                        { label: "Criar senha", value: "senha" }
                                    ]}
                                />
                            </div>
                        </div>
                        <div style={{ display:  tipoEnvio.value === "email" ? "none" : "flex" }} className='row'>

                            <div className='col-md-6'>
                                <label className='required' style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>Senha</label>

                                <div style={{position: "relative"}}>
                                    <input 
                                        onChange={(e) => this.handleChangeSenha(e.target.value, "senha")} 
                                        className="hdv-form-system-password-field" 
                                        type={tipoInput.senha} 
                                        value={detalhesSenha.senha}
                                    />
                                    <i 
                                        onClick={() => this.handlechangeTipoInput("senha")} 
                                        style={{cursor: "pointer", position: "absolute", left: "calc(100% - 35px)", top: "16%", color: "#979797"}} 
                                        className={tipoInput.senha === "password" ? "las la-eye-slash la-2x" : "las la-eye la-2x"}
                                    ></i>
                                </div>
                                <span id='validacao-senha' className={!validacaoSenha.senha ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                            </div>

                            <div className='col-md-6'>
                                <label className='required' style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>Confirmar senha</label>
                                
                                <div style={{position: "relative"}}>
                                    <input 
                                        onChange={(e) => this.handleChangeSenha(e.target.value, "confirmar")} 
                                        className="hdv-form-system-password-field" 
                                        type={tipoInput.confirmar} 
                                        value={detalhesSenha.confirmar}
                                    />
                                    <i 
                                        onClick={() => this.handlechangeTipoInput("confirmar")} 
                                        style={{cursor: "pointer", position: "absolute", left: "calc(100% - 35px)", top: "16%", color: "#979797"}} 
                                        className={tipoInput.confirmar === "password" ? "las la-eye-slash la-2x" : "las la-eye la-2x"}
                                    ></i>
                                </div>
                                <span id='validacao-confirmar' className={!validacaoSenha.confirmar ? "" : "hdv-noshow-item"}>Campo não pode ser nulo!</span>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='hdv-btn-group' style={{margin: "0px"}}>
                        <button 
                            disabled={cooldown}
                            className="hdv-btn-forms hdv-btn-green"
                            onClick={tipoEnvio.value === "email" ? this.enviarConvite : this.criarSenha}
                        >
                            { tipoEnvio.value === "email" ? "Enviar" : "Criar" }
                        </button>
                        <button disabled={cooldown} onClick={() => this.toggleModal(false)} className="hdv-btn-forms hdv-btn-yellow">Cancelar</button>
                    </div>           
                </ModalFooter>
            </Modal>
        )
    }
}

export default EnvioConvite