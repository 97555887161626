import React, { Component } from 'react'
import { Badge, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap'
import ConfirmDelete from '../../components/ConfirmDelete'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import { Link, Redirect } from 'react-router-dom'
import cfg from '../../utils/config'
import auth from '../../utils/auth'
import axios from 'axios'


class Grid extends Component {
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    userInfo = auth.getUserInfo()

    state = {
        automacao: { data: [], loading: true, selected: null },
        grupo: { data: [], loading: true, selected: null },
        redirectToEdit: false,
        limit: 20,
        modal: false,
        lastKey: null,
        end: false,
        noResult: false,
        acionamentos: [],
    }

    timeFormat = (duration) => {
        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = ~~duration % 60

        let ret = ""

        if (mins < 10) {
            mins = "0" + mins
        }

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "")
        ret += "" + secs

        return ret
    }

    getDataAutomacao = (state) => {
        let { automacao } = this.state
        automacao['loading'] = true
        this.setState({ automacao })
        let url = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/automacao/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, this.configMe).then((res) => {
            let { count, results, next, previous } = res.data
            let { automacao } = this.state

            automacao['loading'] = false
            automacao['data'] = results
            automacao['count'] = count
            automacao['next'] = next
            automacao['previous'] = previous

            this.setState({ automacao })
        })
    }

    getDataGrupo = (state) => {
        let { grupo } = this.state
        grupo['loading'] = true
        this.setState({ grupo })
        let url = `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/automacao-grupo/?limit=${state?.lineCount}` + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, this.configMe).then((res) => {
            let { grupo } = this.state
            let { count, results, next, previous } = res.data

            grupo['loading'] = false
            grupo['data'] = results
            grupo['count'] = count
            grupo['next'] = next
            grupo['previous'] = previous

            this.setState({ grupo })
        })
    }

    editar = (resource) => {
        let toGo = `/${resource}/` + this.state?.table?.state?.selected[0] + "/editar"
        let from = { pathname: toGo }
        this.setState({ redirectToEdit: from })
    }

    deletar = (resource) => {
        let requestArray = []
        this.setState({ loading: true })

        this.state?.table?.state?.selected?.map((value, index) =>
            requestArray.push(axios.delete(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/${resource}/${value}/`, this.configMe))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    timeFormat = (duration) => {
        if (!duration) return "---"

        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = ~~duration % 60
        let ret = ""

        if (mins < 10) mins = "0" + mins

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "")
        ret += "" + secs

        return ret
    }

    dateFormat = (timestamp) => {
        if (!timestamp) return "---"
        return new Date(timestamp * 1000).toLocaleDateString("pt-BR", { hour: "numeric", minute: "numeric" })
    }

    getAcionamentos(automacaoId) {
        this.setState({ modal: true, noResult: false })
        let { lastKey, limit, end } = this.state

        if (end) return

        let bodyForm = new FormData()
        bodyForm.append("automacoes", JSON.stringify([automacaoId]))

        if (lastKey != null) {
            bodyForm.append("last_key", JSON.stringify(lastKey))
        }

        axios({
            url: cfg.base_api_url + cfg.api_v2 + `/${this.userInfo.empresa}/automacao/historico/?limit=${limit}`,
            method: "POST",
            data: bodyForm,
            ...this.configMe
        }).then((res) => {
            let acionamentos = this.state.acionamentos
            let noResult = false

            if (res.data?.[automacaoId]?.results?.length > 0) {
                this.setState({
                    lastKey: res.data?.[automacaoId]?.lastKey,
                    end: res.data?.[automacaoId]?.lastKey == null
                })

                res.data?.[automacaoId]?.results?.forEach(automacao => {
                    let data = automacao.dados
                    data.timestamp = automacao.timestamp
                    acionamentos.push(data)
                })
            } else {
                noResult = true
            }

            this.setState({ acionamentos, noResult })
        }).catch(console.error)
    }

    render() {
        const { redirectToEdit, table, automacao, acionamentos } = this.state

        if (redirectToEdit) {
            history.pushState({}, "", location.href)
            return <Redirect to={redirectToEdit} />
        }

        const columnsAutomacao = [{
            Header: 'Descrição',
            Accessor: 'descricao',
            sortable: true,
        }, {
            Header: 'Tolerância',
            Accessor: 'tolerancia',
            sortable: false,
            Cell: (original) => <div>{this.timeFormat(original.tolerancia)}</div>
        }, {
            Header: 'Ordem de execução',
            Accessor: 'ordem',
        }, {
            Header: 'Ativo',
            Accessor: 'ativo',
            sortable: false,
            Cell: (original) => {
                return original.ativo ?
                    <div style={{ fontSize: "13px" }}>
                        <Badge href="#" color="success">Sim</Badge>
                    </div>
                    :
                    <div style={{ fontSize: "13px" }}>
                        <Badge href="#" color="secondary">Não</Badge>
                    </div>
            }
        }, {
            Header: 'Últimos Acionamentos',
            Accessor: 'acionamentos',
            Cell: (original) => (
                <button
                    className="clean-action text-primary clean-btn"
                    onClick={async () => {
                        await new Promise((r, _) => {
                            this.setState({ automacaoId: original?.id, acionamentos: [], lastKey: null, end: false })
                            setTimeout(() => r(), 100)
                        })

                        this.getAcionamentos(original?.id)
                    }}>
                    Visualizar
                </button>
            )
        }]

        const columnsGrupo = [{
            Header: 'Descrição',
            Accessor: 'descricao',
        }, {
            Header: 'Automações',
            Accessor: 'automacoes',
            sortable: false,
            Cell: (original) => (<div>{original?.automacoes?.length ?? "0"}</div>),
        }, {
            Header: 'Data criado',
            Accessor: 'data_criado',
            Cell: (original) => {
                return <div>{new Date(original.data_criado).toLocaleDateString("pt-BR")}</div>
            }
        }]

        return (
            <>
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => this.setState({ modal: false })}
                    className="modal-md">
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Acionamentos</ModalHeader>
                    <ModalBody style={{ minHeight: '500px', overflowY: 'auto' }}>
                        {this.state.noResult ?
                            <div>
                                <p style={{ fontSize: "15px", textAlign: "center", opacity: "0.75", marginTop: "120px" }}>
                                    Sem resultados
                                </p>
                            </div>
                            :
                            <table className="table table-sm afira-table">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Descrição</th>
                                        <th>Tolerância</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {acionamentos.map((acionamento, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{this.dateFormat(acionamento?.timestamp)}</td>
                                                <td>{acionamento?.data?.descricao}</td>
                                                <td>{this.timeFormat(acionamento?.data?.tolerancia)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <div
                            className="report-table-pagination mb-0"
                            style={{ display: acionamentos?.length > 0 ? "flex" : "none", margin: "0 auto" }}>
                            <button className="hdv-btn-forms hdv-btn-blue" onClick={() => this.getAcionamentos(this.state?.automacaoId)}>
                                Carregar Mais
                            </button>
                            <select value={this.state.limit} onChange={(e) => this.setState({ limit: e.target.value })}>
                                <option value="20">20 linhas</option>
                                <option value="50">50 linhas</option>
                                <option value="100">100 linhas</option>
                            </select>
                        </div>
                    </ModalFooter>
                </Modal>

                <div className="hdv-render-div">
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Cadastro de Automações</h4>
                            <div className="gray-background">
                                <i className="fa fa-robot fa-2x"></i>
                            </div>
                        </span>
                    </div>
                    <div className="hdv-btn-group">
                        <ButtonsGrid
                            route="/automacao"
                            buttons={[{
                                method: "post",
                                button: <Link to="/automacao/novo"><button className="hdv-btn-forms hdv-btn-green">Novo</button></Link>
                            }, {
                                method: "patch",
                                button: <button
                                    onClick={() => { if (table?.state?.enableEditar) { this.editar("automacao") } }}
                                    className={"hdv-btn-forms hdv-btn-yellow " + (table?.state?.enableEditar ? "" : "hdv-btn-disable")}>
                                    Editar
                                </button>
                            }, {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state?.enableDeletar} action={() => this.deletar("automacao")} />
                            }]}
                        />
                    </div>
                    <Table
                        checkbox
                        data={automacao?.data}
                        loading={automacao?.loading}
                        count={automacao?.count}
                        next={automacao?.next}
                        previous={automacao?.previous}
                        columns={columnsAutomacao}
                        onFetchData={this.getDataAutomacao}
                        onUpdate={(table) => this.setState({ table })}
                    />
                </div>

                {/*
                 <Tabs>
                  <TabList>
                       <Tab>Automações</Tab>
                       <Tab>Grupos de Automação</Tab>
                     </TabList>
                  <TabPanel>
                  </TabPanel>
                <TabPanel>
                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Grupo de Automação</h4>
                        <div className="gray-background">
                            <i className="fa fa-robot fa-2x"></i>
                        </div>
                    </span>
                </div>
                <div className="hdv-btn-group">
                    <Link to="/automacao-grupo/novo">
                        <button className="hdv-btn-forms hdv-btn-green">Novo</button>
                    </Link>
                    <button
                        onClick={() => { if (table?.state?.enableEditar) { this.editar("automacao-grupo") } }}
                        className={"hdv-btn-forms hdv-btn-yellow " + (table?.state?.enableEditar ? "" : "hdv-btn-disable")}>
                        Editar
                    </button>
                    <ConfirmDelete enabled={table?.state?.enableDeletar} action={() => this.deletar("automacao-grupo")} />
                </div>
                <Table
                     checkbox
                     data={grupo?.data}
                     loading={grupo?.loading}
                     count={grupo?.count}
                     next={grupo?.next}
                     previous={grupo?.previous}
                     columns={columnsGrupo}
                     onFetchData={this.getDataGrupo}
                     onUpdate={(table) => this.setState({ table })}
                 />
                </TabPanel>
             </Tabs>
             */}
            </>
        )
    }
}

export default Grid
