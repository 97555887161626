import React, { Component } from 'react'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import * as rdrLocales from 'react-date-range/dist/locale'
import { DateRangePicker } from 'react-date-range'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import moment from 'moment'
import axios from 'axios'


export default class Relatorio extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { data: "" },
        validacao_formulario: { data: true },
        ana: { intervencao: '' },
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        reporte: { reporte: [] },
        csv: { data: [], columns: [] },
        carregandoDados: false,
        relatorioGerado: false,
        dataFormatada: ''
    }

    componentDidMount() {
        if (this.props.match.params.id){
            this.getData()
        }
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhesDefault
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    showDatePicker = () => this.setState({ datePickerShow: !this.state.datePickerShow })

    getData() {
        axios.get(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/ana/${this.props.match.params.id}`, this.config_me)
        .then(res => {
            let { ana } = this.state

            ana["intervencao"] = res.data?.intervencao

            this.setState({ ana })
        })
    }

    buscarDadosRelatorio = async () => {
        const { detalhesDefault } = this.state
        let validacao = true

        detalhesDefault.data === '' ? validacao = false : validacao = true

        this.setState({ validacao_formulario: { data: validacao } })

        if(!validacao) {    
            return
        }

        this.setState({ carregandoDados: true, relatorioGerado: false })
        
        const formData = new FormData()
        let data = detalhesDefault.data.split('-')
        let data_inicio = data[0].split('/').reverse().join('-') + ' 00:00:00'
        let data_fim = data[1].split('/').reverse().join('-') + ' 23:59:59'

        formData.append('data_inicio', data_inicio)
        formData.append('data_fim', data_fim)

        await axios({
            method: 'POST',
            url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/ana/${this.props.match.params.id}/relatorio/`,
            data: formData,
            ...this.config_me,
        })
        .then(res => {
            let reporte = []
            let csv = { data: [], columns: [] }

            if (res.data && res.data?.reporte) {
                reporte = {
                    reporte: res.data.reporte ?? [],
                }


                if (reporte?.reporte?.length > 0) {
                    reporte?.reporte.forEach((rep) => {
                        const leitura = rep.leituras[0]

                        let obj = {
                            'data_hora': this.convertToDate(rep.timestamp),
                            'duracao': leitura.duracao,
                            'vazao': leitura.vazao,
                            'volume': leitura.volume,
                            'intervencao': rep.intervencao
                        }

                        csv.data.push(obj)
                    })
                }

                csv.columns = [
                    { 'id': 'data_hora', 'displayName': 'Data e Hora' },
                    { 'id': 'duracao', 'displayName': 'Duração' },
                    { 'id': 'vazao', 'displayName': 'Vazão' },
                    { 'id': 'volume', 'displayName': 'Volume' },
                    { 'id': 'intervencao', 'displayName': 'Intervenção' },
                ]
            }

            this.setState({ reporte: reporte, csv: csv, relatorioGerado: true })
        })
        .catch(err => console.log(err))

        this.setState({ carregandoDados: false })
    }

    convertToDate = timestamp => moment(timestamp * 1000).format('D/M/Y H:mm:ss')

    render() {
        const { relatorioGerado, reporte, ana, carregandoDados, validacao_formulario } = this.state

        return (
            <>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Relatório ANA</h4>
                            <div className="gray-background">
                                <i className="fa fa-network-wired fa-2x"></i>
                            </div>
                        </span>
                        <ReactLoading className={carregandoDados ? "hdv-report-loading pb-5" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
                    </div>
                    
                    <details open={true} className='custom-summary'>
                        <summary>Filtros</summary>

                        <form className="hdv-form-default" style={{ padding: "20px", margin: '0' }}>
                            <div className='row'>

                                <div className="col-md-6">
                                    <label className='required'>Periodo</label>
                                    
                                    <InputGroup className="hdv-force-hight">
                                        <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                        <InputGroupAddon addonType="append">
                                            <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                                <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                                {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {!validacao_formulario.data ? <span id='validacao-data'>Campo não pode ser nulo!</span> : null}

                                    <div className={this.state.datePickerShow ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                        <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <label className="required">Intervenção</label>
                                    <input type="text" value={ana.intervencao} readOnly disabled />
                                </div>
                            </div>
                        </form>
                    </details>

                    <div style={{ display: 'flex' }} className="hdv-btn-group">
                        <button
                            style={{ width: 'fit-content' }}
                            disabled={this.state.carregandoDados}
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={this.buscarDadosRelatorio} >Gerar Relatório
                        </button>
                        

                        <CsvDownloader
                            style={{ border: 'none' }}
                            columns={this.state.csv.columns}
                            datas={this.state.csv.data}
                            filename={`relatorio_sideccr_${new Date().getTime()}.csv`}
                            separator=";">
                            <button disabled={relatorioGerado && this.state.csv.data.length > 0 ? false : true} className="hdv-btn-forms hdv-btn-green ">Exportar CSV</button>
                        </CsvDownloader>
                    </div>
                    
                    <div>
                        {reporte?.reporte?.length > 0 ?
                           <div style={{margin: '20px 0px'}}>
                                <table className="table afira-table">
                                    <thead>
                                        <tr>
                                            <th>Data e Hora</th>
                                            <th>Duração</th>
                                            <th>Vazão</th>
                                            <th>Volume</th>
                                            <th>Intervenção</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reporte?.reporte?.map((rep, index) => {
                                            const leitura = rep.leituras[0]
                                            const dataHora = this.convertToDate(rep.timestamp)
                                            
                                            return(
                                                <tr key={index}>
                                                    <td>{dataHora}</td>
                                                    <td>{leitura.duracao}</td>
                                                    <td>{leitura.vazao}</td>
                                                    <td>{leitura.volume}</td>
                                                    <td>{rep.intervencao}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        : relatorioGerado ?
                            <p style={{ textAlign: 'center', color: '#9c2f2f', fontWeight: 'bold' }}>
                                Relatório não gerado
                            </p>
                        : null}
                    </div>
                </div>
            </>
        )
    }
}
