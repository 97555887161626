import React, { Component } from 'react'
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap'
import * as rdrLocales from 'react-date-range/dist/locale'
import { DateRangePicker } from 'react-date-range'
import ReactLoading from 'react-loading'
import CsvDownloader from 'react-csv-downloader'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import moment from 'moment'
import axios from 'axios'


export default class Relatorio extends Component {
    userInfo = auth.getUserInfo()
    config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }

    state = {
        detalhesDefault: { data: "" },
        mira: { rotulo: '', chave_operacao: '' },
        datePickerShow: false,
        dateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        validacao_formulario: { data: true },
        reporte: { reporte: [] },
        csv: { data: [], columns: [] },
        carregandoDados: false,
        relatorioGerado: false,
        dataFormatada: '',
        codigoOptions: []
    }

    componentDidMount() {
        if (this.props.match.params.id)
            this.getData()
    }

    handleSelect = (ranges) => {
        let detalhes = this.state.detalhesDefault
        detalhes.data = ranges.selection.startDate.toLocaleDateString() + "-" + ranges.selection.endDate.toLocaleDateString()
        this.setState({
            dateRange: {
                startDate: ranges.selection.startDate,
                endDate: ranges.selection.endDate,
                key: 'selection',
            },
            dataFormatada: ranges.selection.startDate.toLocaleDateString() + " - " + ranges.selection.endDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    showDatePicker = () => this.setState({ datePickerShow: !this.state.datePickerShow })

    manipularData = (sentido) => {
        if (!this.state.detalhesDefault.data)
            return

        let diffTime = Math.abs(this.state.dateRange.endDate.getTime() - this.state.dateRange.startDate.getTime())
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        diffDays = (diffDays === 0) ? 1 : diffDays
        diffDays = (sentido === "avancar") ? diffDays : -Math.abs(diffDays)

        let newStartDate = new Date(this.state.dateRange.startDate.setDate(this.state.dateRange.startDate.getDate() + diffDays))
        let newEndDate = new Date(this.state.dateRange.endDate.setDate(this.state.dateRange.endDate.getDate() + diffDays))

        let detalhes = this.state.detalhes
        detalhes.data = newStartDate.toLocaleDateString() + "-" + newEndDate.toLocaleDateString()

        this.setState({
            dateRange: { startDate: newStartDate, endDate: newEndDate, key: 'selection' },
            dataFormatada: newStartDate.toLocaleDateString() + " - " + newEndDate.toLocaleDateString(),
            detalhes: detalhes
        })
    }

    getData() {
        axios.get(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/mira/${this.props.match.params.id}`, this.config_me)
        .then(res => {
            let { mira } = this.state

            mira["rotulo"] = res.data?.rotulo
            mira["chave_operacao"] = res.data?.chave_operacao
            mira["tipo"] = res.data?.tipo

            this.setState({ mira })
        })
    }

    buscarDadosRelatorio = async () => {
        const { detalhesDefault, mira, validacao_formulario } = this.state
        let validacao = true

        detalhesDefault.data === '' ? validacao = false : validacao = true
        validacao_formulario.data = validacao

        if(!validacao) {
            this.setState({ validacao_formulario })
            return
        }

        this.setState({ carregandoDados: true, relatorioGerado: false })
        
        const formData = new FormData()
        let data = detalhesDefault.data.split('-')
        let data_inicio = new Date(data[0].split('/').reverse().join('-') + ' 00:00:00').getTime() / 1000
        let data_fim = new Date(data[1].split('/').reverse().join('-') + ' 23:59:59').getTime() / 1000

        formData.append('rotulo', mira.rotulo)
        formData.append('chave_operacao', mira.chave_operacao)
        formData.append('data_inicio', data_inicio)
        formData.append('data_fim', data_fim)

        await axios({
            method: 'POST',
            url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/mira/${this.props.match.params.id}/relatorio/`,
            data: formData,
            ...this.config_me,
        })
        .then(res => {
            let { reporte } = this.state
            let csv = { data: [], columns: [] }

            if (res.data && res.data?.reporte) {
                reporte = {
                    reporte: res.data.reporte ?? [],
                }


                if (reporte?.reporte?.length > 0) {
                    reporte?.reporte.map((dt) => {
                        let obj = {
                            'data_fechado': this.convertToDate(dt.timestamp),
                            'data_utilizado': this.convertToDate(dt.timestamp),
                            'vazao': Number(dt.vazao).toFixed(4),
                        }
                        mira.tipo === "disponibilidade" ? obj['nivel'] =  Number(dt.nivel).toFixed(4) : obj['volume'] =  Number(dt.volume).toFixed(4)
                        csv.data.push(obj)
                    })
                }

                csv.columns = [
                    { 'id': 'data_fechado', 'displayName': 'Data fechado' },
                    { 'id': 'data_utilizado', 'displayName': 'Data utilizado' },
                    { 'id': 'vazao', 'displayName': 'Vazão' }
                ]

                csv.columns.push( mira.tipo === "disponibilidade" ?  { 'id': 'nivel', 'displayName': 'Nível' } :  { 'id': 'Volume', 'displayName': 'Volume' })
            }

            this.setState({ reporte: reporte, csv: csv, relatorioGerado: true })
        })
        .catch(err => console.log(err))

        this.setState({ carregandoDados: false })
    }

    convertToDate = timestamp => moment(timestamp * 1000).format('D/M H:mm:ss')

    render() {
        const { relatorioGerado, reporte, mira, carregandoDados, validacao_formulario } = this.state

        return (
            <>
                <div className="col-md-12 hdv-bloco-top-space hdv-zera-padding-left-right-boostrap">
                    <div className="hdv-default-header mx-3">
                        <span className="screen-menu-desc">
                            <h4>Relatório MIRA</h4>
                            <div className="gray-background">
                                <i className="fa fa-network-wired fa-2x"></i>
                            </div>
                        </span>
                        <ReactLoading className={carregandoDados ? "hdv-report-loading pb-5" : "hdv-noshow-item"} type="cylon" color="#589bd4" height={15} width={50} />
                    </div>
                    
                    <details open={true} className='custom-summary'>
                        <summary>Filtros</summary>
                        <form className="row col-md-12" style={{ padding: "20px", margin: '0' }}>
                            <div className="col-md-5 hdv-top15" style={{ padding: '0' }}>
                                <label style={{ margin: '0', fontWeight: 'bold', color: '#979797'}}>Periodo</label>
                                <InputGroup className="hdv-force-hight">
                                    <Input className="hdv-force-hight" placeholder="Selecione o periodo no botão 'Exibir Calendário' " onChange={() => false} disabled value={this.state.dataFormatada} />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={this.showDatePicker} color={(this.state.datePickerShow) ? "danger" : "primary"}>
                                            <i className={(this.state.datePickerShow) ? "fa fa-calendar-times-o" : "fa fa-calendar-check-o"}></i>
                                            {(this.state.datePickerShow) ? "Fechar Calendário" : "Exibir Calendário"}
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                {!validacao_formulario.data ? <span id='validacao-data'>Campo não pode ser nulo!</span> : null}

                                <div className={this.state.datePickerShow ? "hdv-datepicker" : "hdv-datepicker-display-none"}>
                                    <DateRangePicker locale={rdrLocales.pt} ranges={[this.state.dateRange]} onChange={this.handleSelect} />
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-3 hdv-form-default">
                                <label className="required">Rótulo
                                    <input type="text" value={mira.rotulo} readOnly disabled />
                                </label>
                            </div>

                            <div className="col-md-3 hdv-form-default" style={{ paddingRight: '0' }}>
                                <label className="required">Chave Operação
                                    <input type='text' value={mira.chave_operacao} readOnly disabled/>
                                </label>
                            </div>
                        </form>
                    </details>

                    <div style={{ display: 'flex' }} className="hdv-btn-group">
                        <button
                            style={{ width: 'fit-content' }}
                            disabled={this.state.carregandoDados}
                            className="hdv-btn-forms hdv-btn-blue"
                            onClick={this.buscarDadosRelatorio} >Gerar Relatório
                        </button>
                        

                        <CsvDownloader
                            style={{ border: 'none' }}
                            columns={this.state.csv.columns}
                            datas={this.state.csv.data}
                            filename={`relatorio_sideccr_${new Date().getTime()}.csv`}
                            separator=";">
                            <button disabled={relatorioGerado && this.state.csv.data.length > 0 ? false : true} className="hdv-btn-forms hdv-btn-green ">Exportar CSV</button>
                        </CsvDownloader>
                    </div>
                    
                    <div>
                        {reporte?.reporte?.length > 0 ?
                            <div style={{margin: '20px 0px'}}>
                                <table className="table afira-table">
                                    <thead>
                                        <tr>
                                            <th>Vazão</th>
                                            <th>{mira.tipo === "disponibilidade" ? "Nível" : "Volume"}</th>
                                            <th>Volume</th>
                                            <th>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reporte?.reporte?.map((rep, index) => (
                                            <tr key={index}>
                                                <td hdv-title={rep?.vazao}>{Number(rep?.vazao).toFixed(4)}</td>
                                                <td>{mira.tipo === "disponibilidade" ? rep?.nivel : rep?.volume}</td>
                                                <td>{this.convertToDate(rep?.timestamp)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        : relatorioGerado ?
                            <p style={{ textAlign: 'center', color: '#9c2f2f', fontWeight: 'bold' }}>
                                Relatório não gerado
                            </p>
                        : null}
                    </div>
                </div>
            </>
        )
    }
}
