import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import ConfirmDelete from '../../components/ConfirmDelete'
import ButtonsGrid from '../../components/ButtonsGrid'
import Table from '../../components/Table'
import auth from '../../utils/auth'
import cfg from '../../utils/config'
import helper from '../../utils/helper'
import { Badge } from 'reactstrap'
import axios from 'axios'
import EnvioConvite from './envioConvite'

class Grid extends Component {

    state = {
        data: [],
        loading: true,
        enableConvite: false,
        redirectToEdit: false,
        modalEnvioConvite: false
    }

    getData = (state) => {
        this.setState({ loading: true })
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        let userInfo = auth.getUserInfo()
        let url = cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/?limit=' + state?.lineCount + state.filter

        if (state.url) {
            url = state.url
        }

        axios.get(url, config_me).then((response) => {
            this.setState({
                data: response.data.results,
                loading: false,
                count: response.data.count,
                next: response.data.next,
                previous: response.data.previous,
            })
        })
    }

    deletar = () => {
        let requestArray = []
        let userInfo = auth.getUserInfo()
        let config_me = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
        this.setState({ loading: true, enableDeletar: false, enableEditar: false })

        this.state.table?.state.selected.map((value, index) =>
            requestArray.push(axios.delete(cfg.base_api_url + cfg.api_v2 + '/' + userInfo.empresa + '/cliente/' + value + '/', config_me))
        )

        axios.all(requestArray).then(axios.spread((acct, perms) => {
            this.state.table?.fetch()
            this.state.table?.unselect()
        }))
    }

    editar = () => {
        this.setState({
            redirectToEdit: { pathname: "cliente/" + this.state.table?.state.selected[0] + "/editar" }
        })
        this.state.table?.unselect()
    }

    
    toggleModal = (status) => {
        this.setState({modalEnvioConvite: status})
    }

    render() {
        const { redirectToEdit, table, modalEnvioConvite } = this.state
        
        if (redirectToEdit) {
            history.pushState({}, "", location.href)
            return <Redirect to={redirectToEdit} />
        }

        const columns = [{
            Header: 'Razão Social',
            Accessor: 'razao_social'
        }, {
            Header: 'Nome Fantasia',
            Accessor: 'nome_fantasia'
        }, {
            Header: 'Pessoa',
            Accessor: 'pessoa_tipo',
            Cell: (original) => {
                if(original.pessoa_tipo) {
                    let status = original.pessoa_tipo === "fisica" ? "Física" : "Jurídica"
                    let color = original.pessoa_tipo === "fisica" ? "primary" : "dark"
    
                    return <Badge href="#" color={color}>{status}</Badge>
                }

                return "--"
            }
        }, {
            Header: 'CPF/CNPJ',
            Accessor: 'cpf_cnpj',
            Cell: (original) => {
                let cpf_cnpj = helper.convertCpfCnpj(original.cpf_cnpj)

                return cpf_cnpj ?? "--"
            }
        }, {
            Header: 'Status',
            Accessor: 'status',
            Cell: (original) => {
                let status = original.status
                let color = status === "ativo" ? "success" : status === "cancelado" ? "secondary" : "danger"

                return <Badge href="#" color={color}>{status}</Badge>
            }
        }, {
            Header: 'Criado em',
            Accessor: 'data_criado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_criado)}</span>
            }
        }, {
            Header: 'Atualizado em',
            Accessor: 'data_atualizado',
            Cell: (original) => {
                return <span>{helper.convertDate(original.data_atualizado)}</span>
            }
        }]

        return (
            <div>
                <EnvioConvite
                    statusModal={modalEnvioConvite}
                    toggleModal={(status) => this.toggleModal(status)}
                    clienteSelecionado={this.state.table?.state.selected[0]}
                />

                <div className="hdv-default-header mx-3">
                    <span className="screen-menu-desc">
                        <h4>Cadastro de Clientes</h4>
                        <div className="gray-background">
                            <i className="fa fa-users fa-2x"></i>
                        </div>
                    </span>
                </div>

                <div className="hdv-btn-group">
                    <ButtonsGrid
                        route="/cliente"
                        buttons={[
                            {
                                method: "post",
                                button: <Link to="/cliente/novo"><button className="hdv-btn-forms hdv-btn-green">Novo</button></Link>
                            }, {
                                method: "patch",
                                button: <button onClick={() => { if (table?.state.enableEditar) { this.editar() } }} className={"hdv-btn-forms hdv-btn-yellow " + (table?.state.enableEditar ? "" : "hdv-btn-disable")}>Editar</button>,
                            }, {
                                method: "delete",
                                button: <ConfirmDelete enabled={table?.state.enableDeletar} action={this.deletar} />
                            }
                        ]}
                    />

                    <button onClick={() => { if (this.state.enableConvite) { this.toggleModal(true) } }} className={"hdv-btn-forms hdv-btn-blue " + (this.state.enableConvite ? "" : "hdv-btn-disable")}>
                        Enviar Convite / Criar Senha
                    </button>
                </div>

                <Table
                    checkbox
                    notEditFirstColum={auth.getUserInfo()?.tipo == "cliente"}
                    data={this.state.data}
                    loading={this.state.loading}
                    count={this.state.count}
                    next={this.state.next}
                    previous={this.state.previous}
                    columns={columns}
                    onFetchData={this.getData}
                    onUpdate={(table) => this.setState({
                        table,
                        enableConvite: table.state?.selected?.length === 1
                    })}
                />
            </div>
        )
    }
}

export default Grid
