import React, { Component } from "react"

class Tela404 extends Component {
    render() {
        return (
            <div className="row" style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div style={{ textAlign: "center", margin: "120px 0" }}>
                    <i style={{ fontSize: "150px" }} className="las la-unlink"></i>
                    <h3 style={{ fontWeight: "bold", opacity: "0.7" }}>Recurso indisponível</h3>
                </div>
            </div>
        )
    }
}

export default Tela404