
import { Component } from "react"
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledDropdown
} from 'reactstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import cfg from "../../../utils/config"
import auth from "../../../utils/auth"
import helper from "../../../utils/helper"
import axios from "axios"

class TemplateParametrizacao extends Component {
    userInfo = auth.getUserInfo()
    configMe = { headers: { 'Authorization': 'Bearer ' + auth.getToken() } }
    baseUrl = cfg.base_api_url + cfg.api_v2

    state = {
        detalhes: { descricao: "", template: "" },
        modal: { importar: false, exportar: false, salvar: false },
        jsonData: { importar: "", exportar: "" },
        tableParametros: [],
        selected: []
    }

    componentDidMount() {
        this.getTemplates()
    }

    getTemplates = () => {
        axios.get(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/parametrizacao-template/?limit=100`, this.configMe).then((res) => {
            let tableParametros = res.data?.results ?? []

            if (this.props.parametrizacoes.length > 0) {
                let { jsonData } = this.state

                jsonData["exportar"] = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.props.parametrizacoes))
                this.setState({ jsonData })
            }

            this.setState({ tableParametros })
        })
    }

    handleModal = (state) => {
        let { modal } = this.state
        modal[state] = !modal[state]
        this.setState({ modal })
    }

    handleChange = (e) => {
        let { detalhes } = this.state
        detalhes[e.target.name] = e.target.value
        this.setState({ detalhes })
    }

    toggleRow = (e, id) => {
        this.setState({ selected: (e.target.checked) ? [id] : [] })
    }

    saveTemplate = () => {
        const { parametrizacoes } = this.props
        const { detalhes } = this.state

        if (parametrizacoes?.length > 0) {
            if (!detalhes.descricao) return

            const body = new FormData()
            body.append("descricao", detalhes.descricao)
            body.append("template", JSON.stringify(parametrizacoes))

            axios({
                method: "POST",
                url: `${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/parametrizacao-template/`,
                data: body,
                ...this.configMe
            }).then(() => {
                let { detalhes } = this.state
                detalhes.descricao = ""
                this.setState({ detalhes })
                this.handleModal("salvar")

                helper.dispatchEvent("showAviso", { message: "Template cadastrado com sucesso" })
            })
        }
    }

    importar = async () => {
        let { detalhes, jsonData, selected } = this.state

        const createBody = (template) => {
            const body = new FormData()
            body.append("equipamento", this.props.detalhes.id)
            body.append("descricao", template.descricao)
            body.append("identificacao", template.identificacao)
            body.append("formula", template.formula)
            body.append("unidade", template.unidade)
            body.append("decimal", template.decimal)
            body.append("operador_especial", template.operador_especial)
            body.append("prioridade", template.prioridade)
            body.append("icone", template.icone)
            body.append("tipo_dado_saida", template.tipo_dado_saida)
            body.append("tipo_grafico", template.tipo_grafico)

            return body
        }

        if (selected.length === 1) {
            let requestArray = []
            const templates = this.state.tableParametros.find(tc => tc.id === selected[0])?.template

            templates.forEach(template => {
                const body = createBody(template)
                requestArray.push(axios.post(`${this.baseUrl}/${this.userInfo.empresa}/campo-customizado/`, body, this.configMe))
            })

            if (requestArray.length > 0) {
                await axios.all(requestArray)
                this.props?.getData()
            }

        } else if (jsonData.importar) {
            const reader = new FileReader()

            reader.onload = async (e) => {
                let requestArray = []
                try {
                    const templates = JSON.parse(e.target.result)

                    templates.forEach(template => {
                        const body = createBody(template)
                        requestArray.push(axios.post(`${this.baseUrl}/${this.userInfo.empresa}/campo-customizado/`, body, this.configMe))
                    })

                    if (requestArray.length > 0) {
                        await axios.all(requestArray)
                        this.props?.getData()
                    }
                } catch (err) {
                    helper.dispatchEvent("showAviso", { message: "Erro ao processar arquivo!" })
                }
            }

            reader.readAsText(jsonData.importar)
        }

        detalhes.template = ""
        jsonData.importar = ""
        this.setState({ detalhes, jsonData, selected: [] })
        this.handleModal("importar")
    }

    close = () => {
        let { detalhes, jsonData } = this.state
        detalhes.template = ""
        jsonData.importar = ""
        this.setState({ detalhes, selected: [] })
        this.handleModal("importar")
    }

    removeTemplate = (id) => {
        axios.delete(`${cfg.base_api_url + cfg.api_v2}/${this.userInfo.empresa}/parametrizacao-template/${id}/`, this.configMe).then(() => {
            this.getTemplates()
            this.props?.getData()
        })
    }

    render() {
        const { modal, jsonData, detalhes, tableParametros } = this.state

        return (
            <>
                <Modal isOpen={modal.importar} toggle={() => this.handleModal("importar")} className="modal-md">
                    <ModalHeader toggle={() => this.handleModal("importar")}>Importar Parâmetros</ModalHeader>
                    <ModalBody style={{ minHeight: "300px" }} className="p-1 px-3">
                        <Tabs>
                            <TabList>
                                <Tab>Nuvem</Tab>
                                <Tab>Arquivo Local</Tab>
                            </TabList>
                            <TabPanel>
                                {tableParametros.length === 0
                                    ?
                                    <p className="text-center">Não há nada cadastrado</p>
                                    :
                                    <table className="table table-sm" hidden={tableParametros.length === 0}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Descrição</th>
                                                <th>Parâmetros</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableParametros?.map((parametro, index) => (
                                                <tr key={index}>
                                                    <td style={{ display: "flex", justifyContent: "center", border: "none" }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.selected?.includes(parametro.id)}
                                                            onChange={(e) => this.toggleRow(e, parametro.id)}
                                                        />
                                                    </td>
                                                    <td>{parametro.descricao}</td>
                                                    <td>{parametro.template?.length}</td>
                                                    <td>
                                                        <button
                                                            className="hdv-automacao-delete-button"
                                                            onClick={() => this.removeTemplate(parametro.id)}
                                                            style={{ borderRadius: "6px", padding: "3px 5px", color: "#333", background: "#e0e0e0" }}>
                                                            <i className="fa fa-times" style={{ fontSize: "19px" }}></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </TabPanel>
                            <TabPanel>
                                <div className="hdv-file-picker" style={{ width: "fit-content", textAlign: "center", margin: "20px auto" }}>
                                    <label>Arquivo (Formato JSON)</label>
                                    <br />
                                    <input
                                        type="file"
                                        accept=".json"
                                        name="json-file"
                                        id="json-file"
                                        value={jsonData.importar?.path ?? ""}
                                        onChange={(e) => {
                                            let { jsonData } = this.state
                                            jsonData["importar"] = e.target.files[0]
                                            this.setState({ jsonData })
                                        }}
                                    />
                                    <label style={{ width: "320px" }} htmlFor="json-file">
                                        {jsonData.importar?.name ?? "Selecionar arquivo"}
                                    </label>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.close}>Fechar</Button>
                        <Button color="primary" onClick={this.importar}>Importar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modal.salvar} toggle={() => this.handleModal("salvar")}>
                    <ModalHeader toggle={() => this.handleModal("salvar")}>Salvar Parametrizações</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="required" htmlFor="descricao">Descrição do template</label>
                                <input type="text" name="descricao" id="descricao" value={detalhes.descricao} onChange={this.handleChange} />
                                <span id="validacao-descricao"></span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={() => this.handleModal("salvar")}>Fechar</Button>
                        <Button color="success" onClick={this.saveTemplate}>Salvar</Button>
                    </ModalFooter>
                </Modal>

                <UncontrolledDropdown direction="right" className="float-right">
                    <DropdownToggle nav>
                        <div className="device-export-options">
                            <i className="fa fa-bars" style={{ fontSize: "23px" }}></i>
                            <span>Opções</span>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="mini-modal dropdown-menu dropdown-menu-right dropdown-params">
                        {this.props.parametrizacoes.length > 0
                            ?
                            <DropdownItem className="dropdown-item" onClick={() => this.handleModal("salvar")}>
                                <i className="fa fa-save"></i> Salvar
                            </DropdownItem>
                            :
                            null
                        }
                        <DropdownItem onClick={() => { this.handleModal("importar"); this.getTemplates() }} disabled={!this.props.allowNewParam}>
                            <i className="fa fa-cloud-upload-alt"></i>Importar Parâmetros
                        </DropdownItem>
                        {this.props.parametrizacoes.length > 0
                            ?
                            <a href={jsonData.exportar ?? "#"} download={`parametros_${this.props.detalhes.id ?? "0"}.json`}>
                                <DropdownItem>
                                    <i className="fa fa-cloud-download-alt"></i>Exportar Parâmetros
                                </DropdownItem>
                            </a>
                            :
                            null
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </>
        )
    }
}

export default TemplateParametrizacao